body { 
    font-family: "Inter", sans-serif; 
    margin: 0px;
    height: 100%;
}

html {
    height: 100%;
}

#root {
    height: 100%;
}